import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import {
  PROMO_CODE_BONUS_TYPE_COINS,
  PROMO_CODE_BONUS_TYPE_VIP,
} from 'reducers/promoCode/promoCodeReducer'

export const usePromoCodeDescription = () => {
  const intl = useIntl()

  const { aboutCode } = useShallowEqualSelector(
    ({ promoCode: { aboutCode } }) => ({
      aboutCode,
    })
  )

  if (!aboutCode) {
    return {
      description: '',
    }
  }

  const { bonusType, coinsAmount, vipDays } = aboutCode

  switch (bonusType) {
    case PROMO_CODE_BONUS_TYPE_COINS:
      return {
        description: intl.formatMessage(messages.coins, {
          coinsAmount,
          coinsText: (
            <b>
              <FormattedMessage
                id="promocode.coins_count"
                defaultMessage="{coinsAmount} {coinsAmount, plural, one {монета} few {монеты} many {монет} other {монет}}"
                values={{ coinsAmount }}
              />
            </b>
          ),
        }),
      }

    case PROMO_CODE_BONUS_TYPE_VIP:
      return {
        description: intl.formatMessage(messages.vip, {
          vipDays,
          vipStatus: (
            <b>
              <FormattedMessage
                id="profile.vip_status.title"
                defaultMessage="VIP-статус"
              />
            </b>
          ),
        }),
      }
  }
}

const messages = defineMessages({
  coins: {
    id: 'promocode.coins_description',
    defaultMessage:
      'По промокоду будет начислено {coinsText} сразу после регистрации анкеты',
  },
  vip: {
    id: 'promocode.vip_description',
    defaultMessage:
      'Промокод активирует {vipStatus} на {vipDays} {vipDays, plural, one {день} few {дня} many {дней} other {дней}} сразу после регистрации анкеты',
  },
})
