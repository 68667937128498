import React, { SVGProps } from 'react'

export const PromoCodeSaluteSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.44811 56.1831C1.44811 56.1831 17.0442 39.534 22.3222 32.6694C22.3222 32.6694 23.6932 29.1607 27.4546 30.0945C31.2159 31.0283 33.1629 34.8503 33.78 36.8105C34.397 38.7707 32.081 40.1298 30.4208 41.8678C28.7607 43.6058 15.0834 58.4476 14.2394 59.7824C13.3954 61.1172 12.2599 64.4705 8.37837 64.1233C4.41057 63.7991 0.132857 57.8288 1.44811 56.1831Z"
      fill="#FF791D"
    />
    <path
      d="M10.561 15.3794C10.561 15.3794 11.3899 17.5416 12.3371 18.7925C13.2842 20.0434 16.4678 18.9929 16.4678 18.9929C16.4678 18.9929 19.708 17.927 20.6991 17.0934C21.6902 16.2598 21.9785 15.3024 21.5056 13.1066C21.0327 10.9108 20.75 9.4172 19.498 10.6355C18.246 11.8538 17.1013 11.2217 16.245 9.6322C15.3887 8.04269 14.5567 7.89114 14.1842 9.43655C13.8118 10.982 14.1822 14.1476 12.6091 13.5059C10.9067 12.8363 9.37866 12.8105 10.561 15.3794Z"
      fill="#FFBC36"
    />
    <path
      d="M77.3413 32.8583C77.3413 32.8583 77.2345 31.2206 74.3557 31.097C71.4769 30.9734 70.5096 33.0022 71.2951 34.3036C72.0806 35.605 74.0005 35.4993 75.6117 34.9125C77.1098 34.3817 77.6204 33.7062 77.3413 32.8583Z"
      fill="#6BA5F9"
    />
    <path
      d="M56.8046 42.6045C56.8046 42.6045 56.6831 41.8054 55.2063 42.8475C53.7295 43.8896 52.8563 44.7701 50.8718 43.9152C48.8641 42.974 47.1165 43.3493 48.0724 44.8492C49.0282 46.3491 50.5233 46.411 48.6907 48.1952C46.835 49.8931 45.9339 51.7052 48.222 51.2776C50.4239 50.8731 51.8788 51.1305 52.1279 53.0968C52.3769 55.0631 53.3559 56.6492 55.01 54.5432C56.5778 52.4602 56.4272 51.2069 58.0024 50.8777C59.5776 50.5485 60.4168 50.2315 59.0067 48.7608C57.5966 47.29 57.1302 46.5832 57.3245 45.2375C57.4094 43.8287 56.8046 42.6045 56.8046 42.6045Z"
      fill="#FFBC36"
    />
    <path
      d="M45.8992 0.787504C45.8992 0.787504 44.2631 -0.123952 43.1112 3.02045C41.9593 6.16485 43.1792 8.21529 44.9209 8.39692C46.6626 8.57854 47.7355 4.79221 47.7355 4.79221C47.7355 4.79221 48.3909 1.48335 45.8992 0.787504Z"
      fill="#4ECFA8"
    />
    <path
      d="M77.9837 3.74343C77.9837 3.74343 78.0578 2.98436 76.8336 3.58923C75.6094 4.1941 74.6439 4.72974 73.5508 4.09823C72.544 3.44365 71.0829 2.81823 71.0829 2.81823C71.0829 2.81823 70.3177 2.37617 70.6408 3.58338C70.9639 4.79059 71.4765 5.66988 70.4017 6.14237C69.3268 6.61485 68.1719 7.4784 68.1719 7.4784C68.1719 7.4784 67.6436 8.26664 69.2249 8.30545C70.8293 8.43048 71.2556 9.33284 71.1414 10.2874C71.0504 11.3283 71.2533 13.1221 72.7471 11.7982C74.3271 10.4513 74.6259 10.1865 75.6959 10.7318C76.7659 11.2771 77.6172 11.696 77.4666 10.4427C77.316 9.18931 76.8143 7.66026 76.8143 7.66026C76.8143 7.66026 76.5665 7.07973 77.2612 5.87735C77.9558 4.67496 77.9837 3.74343 77.9837 3.74343Z"
      fill="#FF8A36"
    />
    <path
      d="M29.073 21.5476C29.073 21.5476 29.4628 20.2419 27.5596 20.3817C25.6565 20.5214 26.095 22.1598 26.9695 22.665C27.7577 23.1933 29.2066 23.0827 29.073 21.5476Z"
      fill="#FF791D"
    />
    <path
      d="M57.4779 6.7676C57.4779 6.7676 53.5427 6.07912 51.4866 8.75475C49.4305 11.4304 50.1294 19.3611 50.1294 19.3611C50.1294 19.3611 50.7429 23.7248 45.7634 23.7638C40.7839 23.8029 35.1704 22.1635 33.899 27.7709C33.899 27.7709 33.4121 29.7494 33.5336 30.5485C33.5688 31.3708 34.0279 30.3238 34.2914 29.2368C34.6411 28.1268 35.19 24.6532 39.0885 24.7187C42.9871 24.7841 46.306 26.5781 50.5299 24.0615C54.6446 21.4818 50.4972 12.7942 52.6103 9.6413C54.7234 6.48837 57.3577 7.35421 57.3577 7.35421C57.3577 7.35421 57.9674 7.56065 58.1568 7.23273C58.1738 6.95096 57.4779 6.7676 57.4779 6.7676Z"
      fill="#6D6DF7"
    />
    <path
      d="M38.2682 4.43838C38.2682 4.43838 40.1835 5.03463 40.2832 7.1333C40.3598 9.14573 39.1987 9.64128 39.1987 9.64128C39.1987 9.64128 38.6413 9.9753 38.8647 9.08384C39.0882 8.19238 39.4039 7.64584 38.8683 6.68033C38.3326 5.71481 37.6076 5.07723 37.6076 5.07723C37.6076 5.07723 37.1873 4.54287 38.2682 4.43838Z"
      fill="#FF791D"
    />
    <path
      d="M59.3572 25.3445C59.3572 25.3445 60.675 26.4703 59.6476 28.501C58.6202 30.5316 56.745 29.7399 56.745 29.7399C56.745 29.7399 55.7903 29.6257 55.3653 30.1091C54.9402 30.5925 54.4629 30.5354 54.5431 30.1444C54.6232 29.7533 55.2146 28.8558 56.5542 28.682C57.8938 28.5083 58.9808 28.7718 59.0427 27.2768C59.1046 25.7817 58.575 25.1842 59.3572 25.3445Z"
      fill="#FF791D"
    />
    <path
      d="M4.02711 60.6856C2.66304 59.4037 1.07313 56.7922 2.92617 56.5271C5.15113 56.2088 8.66598 57.9478 10.2315 59.6542C11.7971 61.3605 11.5967 62.3382 10.5681 62.9831C9.75898 63.4904 6.44099 62.9541 4.02711 60.6856Z"
      fill="#B73900"
    />
    <path
      d="M6.79926 60.2958C6.79926 60.2958 3.91387 67.8137 9.76552 72.0691C15.7034 76.3014 24.7331 72.4062 24.7331 72.4062C24.7331 72.4062 27.5896 70.9948 29.5815 73.6035C31.5734 76.2122 29.5815 79.0031 29.5815 79.0031"
      stroke="#2E3D49"
      strokeWidth={0.892642}
      strokeLinecap="round"
    />
    <path
      d="M22.0155 34.5152C22.0155 34.5152 23.01 32.6743 26.3859 33.5204C29.8434 34.3447 31.712 35.7693 31.712 35.7693C31.712 35.7693 33.5586 37.1122 31.9489 38.9428C30.3393 40.7733 28.9272 38.4393 26.7494 38.1473C24.5717 37.8553 23.738 37.0286 21.6155 37.5967C19.5746 38.1429 20.8841 35.5179 22.0155 34.5152Z"
      fill="#E13600"
    />
    <path
      d="M18.0031 41.6934C18.0031 41.6934 21.9447 41.2721 23.6135 42.6736C25.196 44.0982 25.7316 45.0637 24.3994 46.9911C23.0672 48.9186 22.2036 47.7637 21.4384 47.3216C20.6733 46.8795 16.272 44.2358 13.8576 44.882C11.4431 45.5283 14.4954 41.6934 18.0031 41.6934Z"
      fill="#E13600"
    />
    <path
      d="M5.24764 53.1734C7.09439 51.3266 9.56393 50.4527 12.1508 50.8121C14.5146 51.1406 17.1854 51.5747 17.9639 51.948C19.448 52.6596 17.5583 54.9211 17.5583 54.9211L16.1969 56.3944C16.1969 56.3944 15.7889 56.596 15.2022 56.4758C14.6387 56.4418 11.9862 54.472 7.59939 54.9992C3.21261 55.5265 4.04185 54.3792 5.24764 53.1734Z"
      fill="#E13600"
    />
    <path
      d="M4.72862 58.8634C4.72862 58.8634 3.84841 58.7167 4.58192 59.817C5.24207 60.9172 7.80933 61.5774 8.76289 61.7974C9.71644 62.0175 10.2299 61.7974 9.12964 60.6972C7.95603 59.5969 5.75552 58.8634 4.72862 58.8634Z"
      fill="#2E3D49"
    />
    <path
      opacity={0.43}
      d="M10.8901 50.1342C10.8901 50.1342 9.9365 50.3542 10.9634 48.7405C11.9903 47.1268 13.3106 46.0999 13.3106 46.0999C13.3106 46.0999 14.1908 45.5865 14.5576 46.2466C14.9977 46.8334 15.2911 47.2002 13.8241 48.6672C12.4304 50.1342 10.8901 50.1342 10.8901 50.1342Z"
      fill="white"
    />
    <path
      opacity={0.43}
      d="M24.5332 32.9704C24.5332 32.9704 23.7264 32.8237 24.4599 32.0902C25.2667 31.3567 27.7606 31.2833 29.4477 32.9704C31.0614 34.6574 30.2545 34.6574 29.521 34.5107C28.7875 34.364 27.1005 33.1904 24.5332 32.9704Z"
      fill="white"
    />
    <path
      opacity={0.43}
      d="M4.28853 56.4425C4.28853 56.4425 7.00249 56.8826 9.4964 58.7897C9.4964 58.7897 10.3033 59.3765 10.7434 58.0562C11.2568 56.7359 8.1761 55.6357 5.97559 55.709C3.92178 55.8557 3.84843 56.2225 4.28853 56.4425Z"
      fill="white"
    />
    <path
      opacity={0.43}
      d="M18.9587 41.5529C18.9587 41.5529 17.565 41.6996 18.3718 40.5993C19.1787 39.4991 20.1323 38.5455 20.1323 38.5455C20.1323 38.5455 21.966 37.1518 21.8193 38.9123C21.746 40.6727 18.9587 41.5529 18.9587 41.5529Z"
      fill="white"
    />
    <path
      d="M50.1325 48.0077C50.1325 48.0077 48.0054 49.4014 48.6655 49.7681C49.3257 50.1349 51.1594 49.2547 51.3795 48.4478C51.6729 47.641 50.646 47.7877 50.1325 48.0077Z"
      fill="white"
    />
    <path
      d="M17.345 0.989817C17.345 0.989817 16.6848 0.403015 16.9049 1.79667C17.1249 3.19033 17.7851 4.80403 18.592 4.58398C19.3988 4.36393 18.812 1.50327 17.345 0.989817Z"
      fill="white"
      fillOpacity={0.7}
    />
    <path
      d="M70.4378 7.28521C70.4378 7.28521 69.851 7.13851 70.8045 6.47836C71.7581 5.81821 72.4182 5.81821 72.0515 6.91846C71.6847 8.01872 70.4378 7.28521 70.4378 7.28521Z"
      fill="white"
      fillOpacity={0.6}
    />
  </svg>
)
