import { Colors } from 'common-constants/colors'
import { FontSize } from 'common-constants/sizes'
import { PromoCodeSaluteSvg } from 'components/presentational/svg/PromoCodeSaluteSvg'
import { zIndex } from 'components/presentational/zIndex'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled, { css } from 'styled-components'
import { usePromoCodeDescription } from './hooks/usePromoCodeDescription'
import { StyledComponentsStyles } from 'common/types'

const PromoCodeDetails: FC<{ styles?: StyledComponentsStyles }> = ({
  styles,
}) => {
  const { aboutCode } = useShallowEqualSelector(
    ({ promoCode: { aboutCode }, abTest: { promocodereg } }) => ({
      aboutCode,
      promocodereg,
    })
  )

  const { description } = usePromoCodeDescription()

  if (!aboutCode) {
    return null
  }

  return (
    <Wrapper $styles={styles}>
      <Inner>
        <StyledPromocodeSaluteSvg />
        <Description>{description}</Description>
      </Inner>
      <TermsText>
        <FormattedMessage
          id="promocode.for_new_users_only"
          defaultMessage="Промо-код действует только для новых пользователей"
        />
      </TermsText>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $styles?: StyledComponentsStyles }>`
  padding-top: 20px;
  margin-top: auto;
  margin-bottom: 21px;
  ${({ $styles }) => $styles};
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`

const StyledPromocodeSaluteSvg = styled(PromoCodeSaluteSvg)`
  margin-right: 10px;
`

const Description = styled.p`
  font-size: ${FontSize.header}px;
  color: ${Colors.dark};
  max-width: 250px;
  width: 100%;
  position: relative;
  z-index: ${zIndex.base};
`

const TermsText = styled.p`
  font-size: ${FontSize.footer}px;
  color: ${Colors.noteColor};
  text-align: center;
  margin-top: 16px;
  margin-bottom: 0;
`

export default PromoCodeDetails
